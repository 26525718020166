import { GB } from '@clearscore-group/lib.config.i18n';
import { ShadedBox } from '@clearscore/shared.website-careers-components';

import climbing from '../assets/clubs/climbing.svg';
import basketball from '../assets/clubs/basketball.svg';
import book from '../assets/clubs/book.svg';
import boxing from '../assets/clubs/boxing.svg';
import cinema from '../assets/clubs/cinema.svg';
import coffee from '../assets/clubs/coffee.svg';
import games from '../assets/clubs/games.svg';
import yoga from '../assets/clubs/yoga.svg';
import structuredTrainingCourses from '../assets/people/structured_training_courses.svg';
import cultureInclusion from '../assets/people/culture_inclusion.svg';
import fastProgression from '../assets/people/fast_progression.svg';
import generousTrainingAllowance from '../assets/people/generous_training_allowance.svg';
import maternityLeave from '../assets/people/maternity_leave.svg';
import mentalHealthAmbassadors from '../assets/people/mental_health_ambassadors.svg';
import noClockWatching from '../assets/people/no_clock_watching.svg';
import onsitePsychotherapy from '../assets/people/onsite_psychotherapy.svg';
import wellbeingEvents from '../assets/people/wellbeing_events.svg';
import perkbox from '../assets/smallthings/perkbox.svg';
import brownBags from '../assets/smallthings/brown_bags.svg';
import clubBoardGame from '../assets/smallthings/club_board_game.svg';
import dogFriendly from '../assets/smallthings/dog_friendly.svg';
import freeBreakfast from '../assets/smallthings/free_breakfast.svg';
import gpOfficeVisit from '../assets/smallthings/gp_office_visit.svg';
import lifeAssuranceScheme from '../assets/smallthings/life_assurance_scheme.svg';
import pensionContribution from '../assets/smallthings/pension_contribution.svg';
import privateDentalCover from '../assets/smallthings/private_dental_cover.svg';
import weAreFocused from '../assets/principles/we_are_focused.svg';
import weAreProud from '../assets/principles/we_are_proud.svg';
import weDeliver from '../assets/principles/we_deliver.svg';
import weFight from '../assets/principles/we_fight.svg';
import weFailAndFix from '../assets/principles/we_fail_and_fix.svg';
import weLead from '../assets/principles/we_lead.svg';
import weLearn from '../assets/principles/we_learn.svg';
import weObsess from '../assets/principles/we_obsess.svg';

export default {
    [GB]: {
        firstBox: {
            theme: ShadedBox.Theme.GRAY,
            headerSize: ShadedBox.HeaderSize.MASSIVE,
        },
        secondBox: {
            theme: ShadedBox.Theme.SEA,
            headerSize: ShadedBox.HeaderSize.MASSIVE,
            bullets: [
                {
                    key: 'yoga',
                    icon: yoga,
                },
                {
                    key: 'boxing',
                    icon: boxing,
                },
                {
                    key: 'basketball',
                    icon: basketball,
                },
                {
                    key: 'games',
                    icon: games,
                },
                {
                    key: 'climbing',
                    icon: climbing,
                },
                {
                    key: 'cinema',
                    icon: cinema,
                },
                {
                    key: 'book',
                    icon: book,
                },
                {
                    key: 'coffee',
                    icon: coffee,
                },
            ],
        },
        jobListings: {
            viewAllJobs: '/careers/jobs?expand=1',
            maxJobsDisplayed: 5,
        },
        stories: {
            items: [
                {
                    key: 'hannah',
                },
                {
                    key: 'lily',
                },
                {
                    key: 'jayna',
                },
            ],
        },
        ourPeople: {
            sections: [
                {
                    key: 'learning',
                    bullets: [
                        {
                            key: 'generousTrainingAllowance',
                            icon: generousTrainingAllowance,
                        },
                        {
                            key: 'fastProgression',
                            icon: fastProgression,
                        },
                        {
                            key: 'structuredTrainingCourses',
                            icon: structuredTrainingCourses,
                        },
                    ],
                },
                {
                    key: 'wellbeing',
                    bullets: [
                        {
                            key: 'wellbeingEvents',
                            icon: wellbeingEvents,
                        },
                        {
                            key: 'onsitePsychotherapy',
                            icon: onsitePsychotherapy,
                        },
                        {
                            key: 'mentalHealthAmbassadors',
                            icon: mentalHealthAmbassadors,
                        },
                    ],
                },
                {
                    key: 'culture',
                    bullets: [
                        {
                            key: 'noClockWatching',
                            icon: noClockWatching,
                        },
                        {
                            key: 'maternityLeave',
                            icon: maternityLeave,
                        },
                        {
                            key: 'cultureInclusion',
                            icon: cultureInclusion,
                        },
                    ],
                },
            ],
        },
        perks: {
            items: [
                {
                    key: 'privateDentalCover',
                    icon: privateDentalCover,
                },
                {
                    key: 'gpOfficeVisit',
                    icon: gpOfficeVisit,
                },
                {
                    key: 'lifeAssuranceScheme',
                    icon: lifeAssuranceScheme,
                },
                {
                    key: 'pensionContribution',
                    icon: pensionContribution,
                },
                {
                    key: 'brownBags',
                    icon: brownBags,
                },
                {
                    key: 'perkbox',
                    icon: perkbox,
                },
                {
                    key: 'dogFriendly',
                    icon: dogFriendly,
                },
                {
                    key: 'freeBreakfast',
                    icon: freeBreakfast,
                },
                {
                    key: 'clubBoardGame',
                    icon: clubBoardGame,
                },
            ],
        },
        video: {
            key: 'life',
            thumbnail: 'thumbnail',
        },
        principles: {
            items: [
                {
                    key: 'weDeliver',
                    icon: weDeliver,
                },
                {
                    key: 'weLead',
                    icon: weLead,
                },
                {
                    key: 'weAreFocused',
                    icon: weAreFocused,
                },
                {
                    key: 'weFight',
                    icon: weFight,
                },
                {
                    key: 'weAreProud',
                    icon: weAreProud,
                },
                {
                    key: 'weLearn',
                    icon: weLearn,
                },
                {
                    key: 'weFailAndFix',
                    icon: weFailAndFix,
                },
                {
                    key: 'weObsess',
                    icon: weObsess,
                },
            ],
        },
    },
};
