import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';
import ContentCarousel from '@clearscore/ui.rainbow.content-carousel';
import { SectionHeader } from '@clearscore/shared.website-careers-components';

import styles from './principles.module.css';

const Principles = ({ header, subheader, items }) => (
    <div data-qa="principles">
        <SectionHeader {...{ header, subheader }} />
        <div className={styles.items}>
            <ContentCarousel
                inlineAt={ContentCarousel.Size.PHABLET}
                maxInlineItems={{
                    [ContentCarousel.Size.PHABLET]: 2,
                    [ContentCarousel.Size.LARGE]: 4,
                }}
            >
                {/* TODO render images when downloadable from abstract */}
                {items.map(({ name, body, icon: Icon }) => (
                    <div data-qa="principles-item" key={name} className={styles.item}>
                        <div className={styles.icon}>
                            <Icon height="" />
                        </div>
                        <Spacer all={{ top: Spacer.spacings.LARGE, bottom: Spacer.spacings.TINY }}>
                            <Text.H2 tag="h3">{name}</Text.H2>
                        </Spacer>
                        <div className={styles.itemBody}>{body}</div>
                    </div>
                ))}
            </ContentCarousel>
        </div>
    </div>
);

Principles.propTypes = {
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            body: PropTypes.string.isRequired,
            icon: PropTypes.elementType.isRequired,
        }),
    ).isRequired,
};

export default Principles;
