import type { ReactElement, ReactNode } from 'react';
import React, { useState } from 'react';
import cx from 'classnames';
import ChevronDownIcon from '@clearscore/rainbow.icons.chevron-down';
import Text from '@clearscore/ui.rainbow.text';

import styles from './accordion.module.css';
import type { BackgroundColor } from './constants';
import { Type } from './constants';
import AccordionContent from './components/accordion-content';

interface AccordionProps {
    title: string;
    boldHeadings?: boolean;
    backgroundColor?: (typeof BackgroundColor)[keyof typeof BackgroundColor];
    children: ReactElement;
    description?: ReactNode;
    icon?: ReactNode;
    largeIcon?: ReactNode;
    type?: keyof typeof Type;
    dataId?: string;
    isDisabled?: boolean;
    onToggle?: (i: unknown) => void;
    isInitialExpanded?: boolean;
    tag?: ReactNode;
}

export interface AccordionExport {
    (props: AccordionProps): ReactElement;
    Type: typeof Type;
    Content: typeof AccordionContent;
}

const toKebabCase = (value: string): string => value.toLowerCase().replace(/ /g, '-');

const Accordion: AccordionExport = ({
    title,
    boldHeadings = false,
    backgroundColor = null,
    description = null,
    icon = null,
    largeIcon = null,
    children,
    type = Type.PRIMARY,
    dataId = null,
    isDisabled = false,
    onToggle = null,
    isInitialExpanded = false,
    tag,
}): ReactElement => {
    const [isExpanded, setIsExpanded] = useState(isInitialExpanded);
    const formattedAriaTitle = toKebabCase(title);

    return (
        <div
            className={cx(styles.component, {
                [styles.isTypePrimary]: type === Type.PRIMARY,
                [styles.isTypeSecondary]: type === Type.SECONDARY,
            })}
            style={backgroundColor ? { backgroundColor: `var(--accordion-bg-${backgroundColor})` } : undefined}
            data-qa="accordion"
            data-id={dataId}
        >
            <Text.H3>
                <button
                    id={formattedAriaTitle}
                    className={cx(styles.accordionToggle, {
                        [styles.isDisabled]: isDisabled,
                    })}
                    data-qa="accordion-toggle"
                    aria-disabled={isDisabled}
                    aria-expanded={isExpanded}
                    aria-controls={`${formattedAriaTitle}-content`}
                    onClick={(): void => {
                        if (!isDisabled) {
                            setIsExpanded(!isExpanded);
                            if (onToggle) onToggle({ isExpanded: !isExpanded });
                        }
                    }}
                    type="button"
                >
                    <div className={styles.header}>
                        <div className={styles.leftSection}>
                            {icon ? <span className={styles.icon}>{icon}</span> : null}
                            {largeIcon ? <span className={styles.largeIcon}>{largeIcon}</span> : null}
                            <div className={styles.details}>
                                <span
                                    data-qa="accordion-title"
                                    className={cx(styles.title, { [styles.bold]: boldHeadings })}
                                >
                                    {title}
                                </span>
                                {description ? (
                                    <span className={styles.description}>
                                        <Text.Body2 tag={Text.tags.SPAN}>{description}</Text.Body2>
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className={styles.statusArrow}>
                            {tag ? <div className={styles.tag}>{tag}</div> : null}
                            {!isDisabled ? (
                                <span
                                    className={cx(styles.chevron, {
                                        [styles.isExpanded]: isExpanded,
                                    })}
                                    data-qa="accordion-chevron-icon"
                                >
                                    <ChevronDownIcon height="18" width="18" />
                                </span>
                            ) : null}
                        </div>
                    </div>
                </button>
            </Text.H3>
            <div
                id={`${formattedAriaTitle}-content`}
                data-qa="accordion-content"
                aria-labelledby={title}
                role="region"
                hidden={!isExpanded}
            >
                {children}
            </div>
        </div>
    );
};

Accordion.Type = Type;
Accordion.Content = AccordionContent;

export default Accordion;
