import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@clearscore/ui.rainbow.accordion';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';

import styles from './our-people.module.css';

const propTypesSection = PropTypes.shape({
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    bullets: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            icon: PropTypes.elementType.isRequired,
        }),
    ).isRequired,
});

const SectionContent = ({ section }) => (
    <div>
        <Text.H2 tag="h3">{section.header}</Text.H2>
        <div className={styles.sectionContentBody}>
            <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.SMALL }}>
                <Text.Body1>{section.body}</Text.Body1>
            </Spacer>
            <div className={styles.bullets}>
                {section.bullets.map(({ text, icon: Icon }) => (
                    <div key={text} className={styles.bullet}>
                        <Icon className={styles.bulletIcon} />
                        <Spacer all={{ left: Spacer.spacings.MEDIUM }}>
                            <Text.Body1>{text}</Text.Body1>
                        </Spacer>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

SectionContent.propTypes = {
    section: propTypesSection.isRequired,
};

const SectionsLarge = ({ sections }) => (
    <div data-qa="our-people-sections-large" className={styles.sectionsLarge}>
        {sections.map((section) => (
            <SectionContent key={section.header} section={section} />
        ))}
    </div>
);

SectionsLarge.propTypes = {
    sections: PropTypes.arrayOf(propTypesSection.isRequired).isRequired,
};

const SectionsSmall = ({ sections }) => (
    <div data-qa="our-people-sections-small" className={styles.sectionsSmall}>
        {sections.map((section) => (
            <Accordion title={section.header} key={section.header}>
                <Accordion.Content>
                    <Spacer all={{ top: Spacer.spacings.MEDIUM }}>
                        <SectionContent section={section} />
                    </Spacer>
                </Accordion.Content>
            </Accordion>
        ))}
    </div>
);

SectionsSmall.propTypes = {
    sections: PropTypes.arrayOf(propTypesSection.isRequired).isRequired,
};

const OurPeople = ({ header, sections }) => (
    <div data-qa="our-people" className={styles.wrapper}>
        <div className={styles.header}>
            <h2>{header}</h2>
        </div>
        <Spacer all={{ top: Spacer.spacings.LARGE }} large={{ top: Spacer.spacings.SUPER }}>
            <SectionsLarge sections={sections} />
            <SectionsSmall sections={sections} />
        </Spacer>
    </div>
);

OurPeople.propTypes = {
    header: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(propTypesSection.isRequired).isRequired,
};

export default OurPeople;
