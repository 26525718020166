import React from 'react';
import PropTypes from 'prop-types';
import { fontSize } from '@clearscore/ui.rainbow.tokens';
import Spacer from '@clearscore/ui.rainbow.spacer';

import styles from './perks.module.css';

const Perks = ({ header, items }) => (
    <div data-qa="perks">
        <div className={styles.header}>
            <h2>{header}</h2>
        </div>
        <Spacer all={{ vertical: Spacer.spacings.BIG }} large={{ vertical: Spacer.spacings.SUPER }}>
            <div className={styles.items}>
                {items.map(({ text, icon: Icon }) => (
                    <div data-qa="perks-item" key={text} className={styles.item}>
                        <Icon height={fontSize['rnb-font-size-medium']} width={fontSize['rnb-font-size-medium']} />
                        <Spacer all={{ left: Spacer.spacings.MEDIUM }}>{text}</Spacer>
                    </div>
                ))}
            </div>
        </Spacer>
    </div>
);

Perks.propTypes = {
    header: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            icon: PropTypes.elementType.isRequired,
        }).isRequired,
    ).isRequired,
};

export default Perks;
