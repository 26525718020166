import type { ReactElement, ReactNode } from 'react';
import React from 'react';

import styles from './accordion-content.module.css';

interface AccordionContentProps {
    children: ReactNode;
}

interface AccordionContent {
    (props: AccordionContentProps): ReactElement;
}

const AccordionContent: AccordionContent = ({ children }): ReactElement => (
    <div className={styles.component}>{children}</div>
);

export default AccordionContent;
