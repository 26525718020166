import React, { Fragment } from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import CareersLife from '@clearscore/website.careers-life';
// eslint-disable-next-line import/no-webpack-loader-syntax
import lifeVideoSubs from 'url-loader!../assets/global/careers/life/life_video_subs.srt';

import lifeVideo from '../assets/global/careers/life/life_video.mp4';
import Layout from '../components/layout';
import { findImage, findStory, HiddenPageMeta } from './template-util';

const CareersLifeTemplate = ({
    data,
    pageContext: { subNavRoutes, verticalVersion, market },
    location,
}: PageProps<
    Queries.CareersLifeQuery,
    { market: string; verticalVersion: string; subNavRoutes: Array<{ navTitle: string; categoryUrl: string }> }
>): React.ReactElement => {
    const images = {
        hero: findImage(data, 'hero'),
        thumbnail: findImage(data, 'thumbnail'),
        firstBox: findImage(data, 'first_box'),
        secondBox: findImage(data, 'second_box'),
        stories: {
            ...findStory(data, 'hannah'),
            ...findStory(data, 'jayna'),
            ...findStory(data, 'lily'),
        },
    };

    const videos = {
        life: {
            mp4: lifeVideo,
            subtitles: lifeVideoSubs,
        },
    };

    return (
        <Layout
            meta={{
                title: `Life at ClearScore | Careers | ClearScore  ${market.toUpperCase()}`,
                description: '',
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <Fragment>
                    <CareersLife
                        images={images}
                        videos={videos}
                        appStoreConfig={commonModules.app_store}
                        cookiePolicyConfig={commonModules.cookie_policy}
                        categories={subNavRoutes}
                    />
                    <HiddenPageMeta verticalVersion={verticalVersion} />
                </Fragment>
            )}
        </Layout>
    );
};

export const query = graphql`
    query CareersLife {
        images: allFile(filter: { absolutePath: { regex: "/global/careers/life/" } }) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(quality: 99, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                }
            }
        }
    }
`;

export default CareersLifeTemplate;
