import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Template from '@clearscore/shared.website-template';
import {
    CareersNav,
    PageHeader,
    SectionHeader,
    ShadedBox,
    FullWidthImage,
    PlayableVideo,
    JobListings,
    StoriesSection,
    GLASSDOOR_RATING,
} from '@clearscore/shared.website-careers-components';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import { fontSize } from '@clearscore/ui.rainbow.tokens';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';

import marketConfig from './lib/market-config';
import OurPeople from './components/our-people';
import Perks from './components/perks';
import Principles from './components/principles';
import styles from './careers-life.module.css';
import { PAGE_NAMESPACE } from './lib/constants';

const CareersLife = ({ images, videos, appStoreConfig, cookiePolicyConfig, categories }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const {
        jobListings: jobListingsConfig,
        firstBox: firstBoxConfig,
        secondBox: secondBoxConfig,
        stories: storiesConfig,
        ourPeople: ourPeopleConfig,
        video: videoConfig,
        principles: principlesConfig,
        perks: perksConfig,
    } = useMarketConfig(marketConfig);

    const jobListingsCopy = {
        ...t('workable'),
        glassdoorText: t('workable.glassdoorText', { rating: GLASSDOOR_RATING }),
    };

    const getStoryItems = () => {
        const storyItemsCopy = t('stories.items');
        return storiesConfig.items.map(({ key }) => ({ image: images.stories[key], ...storyItemsCopy[key] }));
    };

    const getOurPeopleSections = () => {
        const ourPeopleCopy = t('ourPeople.sections');
        return ourPeopleConfig.sections.map(({ key, bullets }) => ({
            ...ourPeopleCopy[key],
            bullets: bullets.map(({ key: _key, icon }) => ({ ...ourPeopleCopy[key].bullets[_key], icon })),
        }));
    };

    const getPrinciplesItems = () => {
        const principlesItemsCopy = t('principles.items');
        return principlesConfig.items.map(({ key, icon }) => ({ ...principlesItemsCopy[key], icon }));
    };

    const getPerksItems = () => {
        const perksItemsCopy = t('perks.items');
        return perksConfig.items.map(({ key, icon }) => ({ ...perksItemsCopy[key], icon }));
    };

    return (
        <Template shouldHideNav appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
            <CareersNav items={categories} theme={CareersNav.Theme.LIGHT} />
            <article data-layout="full-bleed">
                <Spacer all={{ vertical: Spacer.spacings.SUPER }} large={{ vertical: Spacer.spacings.JUMBO }}>
                    <PageHeader preheader={t('pageHeader.preheader')} header={t('pageHeader.header')} />
                </Spacer>
                <Spacer large={{ horizontal: Spacer.spacings.SUPER }}>
                    <FullWidthImage image={images.hero} alt={t('intro.imageAlt')} />
                </Spacer>
                <SectionHeader header={t('intro.header')} subheader={t('intro.subheader')} />
                <ShadedBox
                    isFlippedMobile
                    header={<q>{t('firstBox.header')}</q>}
                    theme={firstBoxConfig.theme}
                    headerSize={firstBoxConfig.headerSize}
                    image={images.firstBox}
                    alt={t('firstBox.imageAlt')}
                >
                    <Spacer all={{ top: Spacer.spacings.LARGE, bottom: Spacer.spacings.MEDIUM }}>
                        <Text.Body1>
                            <q>{t('firstBox.subheader')}</q>
                        </Text.Body1>
                    </Spacer>
                    <Spacer all={{ bottom: Spacer.spacings.HUGE }}>
                        <Text.Strong>{t('firstBox.footer')}</Text.Strong>
                    </Spacer>
                </ShadedBox>
                <Spacer
                    all={{ horizontal: Spacer.spacings.LARGE, vertical: Spacer.spacings.SUPER }}
                    large={{ horizontal: Spacer.spacings.SUPER, vertical: Spacer.spacings.SMALL }}
                >
                    <OurPeople header={t('ourPeople.header')} sections={getOurPeopleSections()} />
                </Spacer>
                <Spacer
                    all={{ vertical: Spacer.spacings.SUPER, horizontal: Spacer.spacings.BIG }}
                    large={{ vertical: Spacer.spacings.JUMBO, horizontal: Spacer.spacings.SUPER }}
                >
                    <StoriesSection items={getStoryItems()} />
                </Spacer>
                <Spacer
                    all={{ horizontal: Spacer.spacings.LARGE, vertical: Spacer.spacings.SUPER }}
                    large={{ horizontal: Spacer.spacings.SUPER, vertical: Spacer.spacings.SMALL }}
                >
                    <Perks header={t('perks.header')} items={getPerksItems()} />
                </Spacer>
                <PlayableVideo
                    video={videos[videoConfig.key].mp4}
                    subtitles={videos[videoConfig.key].subtitles}
                    thumbnail={images[videoConfig.thumbnail]}
                    thumbnailHeader={t('video.thumbnailHeader')}
                    thumbnailSubheader={t('video.thumbnailSubheader')}
                />
                <Spacer
                    all={{ horizontal: Spacer.spacings.LARGE, vertical: Spacer.spacings.SUPER }}
                    large={{
                        horizontal: Spacer.spacings.SUPER,
                        top: Spacer.spacings.SMALL,
                        bottom: Spacer.spacings.JUMBO,
                    }}
                >
                    <Principles
                        header={t('principles.header')}
                        subheader={t('principles.subheader')}
                        items={getPrinciplesItems()}
                    />
                </Spacer>
                <ShadedBox
                    isFlipped
                    header={t('secondBox.header')}
                    theme={secondBoxConfig.theme}
                    headerSize={secondBoxConfig.headerSize}
                    image={images.secondBox}
                    alt={t('secondBox.imageAlt')}
                >
                    <Spacer all={{ top: Spacer.spacings.LARGE }}>
                        <Text.Body1>{t('secondBox.subheader')}</Text.Body1>
                    </Spacer>
                    <div className={styles.secondBoxBullets}>
                        {secondBoxConfig.bullets.map(({ key, icon: Icon }) => (
                            <div key={key} className={styles.secondBoxBullet}>
                                <Icon
                                    width={fontSize['rnb-font-size-medium']}
                                    height={fontSize['rnb-font-size-medium']}
                                />
                                <Spacer all={{ left: Spacer.spacings.MEDIUM }}>
                                    {t(`secondBox.bullets.${key}.text`)}
                                </Spacer>
                            </div>
                        ))}
                    </div>
                </ShadedBox>
                <JobListings language={jobListingsCopy} config={jobListingsConfig} />
            </article>
        </Template>
    );
};

CareersLife.propTypes = {
    images: PropTypes.shape({
        hero: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        firstBox: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        secondBox: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        thumbnail: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        stories: PropTypes.shape({
            hannah: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            lily: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            jayna: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    videos: PropTypes.object.isRequired,
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryNavPosition: PropTypes.number.isRequired,
            categoryUrl: PropTypes.string.isRequired,
            navTitle: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

CareersLife.defaultProps = {};

export default CareersLife;
