/* eslint-disable import/prefer-default-export */
export const Type = {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
} as const;

export const BackgroundColor = {
    CHILL_BASE: 'chill-base',
    CHILL: 'chill',
    CHILL_TINT_32: 'chill-tint-32',
    MIDNIGHT: 'midnight',
    MIDNIGHT_TINT_4: 'midnight-tint-4',
    LIGHT: 'light',
    TRANSPARENT: 'transparent',
    WHITE: 'white',
    SAND: 'sand',
    ROSE: 'rose',
    SEA: 'sea',
    REEF: 'reef',
    SALVIA_BASE: 'salvia-base',
    SALVIA: 'salvia',
    SALIVIA_TINT_32: 'salvia-tint-32',
    SKY_BASE: 'sky-base',
    SKY: 'sky',
    SKY_TINT_32: 'sky-tint-32',
    SLATE_BASE: 'slate-base',
    SLATE_TINT_10: 'slate-tint-10',
} as const;
